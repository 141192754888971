<template>
  <div class="noorplay_footer" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <div class="container footer">
      <div class="logo_container">
        <div class="flex_container">
          <img src="@/assets/icons/NoorPlayLogo.png" class="image_item" alt />
          <ul class="icon_list">
            <li class="icon" v-if="appConfig.providerDetails.social.facebook">
              <a
                :href="appConfig.providerDetails.social.facebook"
                target="_blank"
                rel="noopener"
                aria-label="facebook link"
              >
                <img src="@/assets/icons/newfb.svg" alt="facebook_img" />
              </a>
            </li>
            <li class="icon" v-if="appConfig.providerDetails.social.instagram">
              <a
                :href="appConfig.providerDetails.social.instagram"
                target="_blank"
                rel="noopener"
                aria-label="instagram link"
              >
                <img src="@/assets/icons/newinsta.svg" alt="instagram_img" />
              </a>
            </li>
            <li class="icon" v-if="appConfig.providerDetails.social.twitter">
              <a
                :href="appConfig.providerDetails.social.twitter"
                target="_blank"
                rel="noopener"
                aria-label="twitter link"
              >
                <img src="@/assets/icons/Twitte.svg" alt="twitter_img" />
              </a>
            </li>
            <li class="icon" v-if="appConfig.providerDetails.social.pinterest">
              <a
                :href="appConfig.providerDetails.social.pinterest"
                target="_blank"
                rel="noopener"
                aria-label="pinterest link"
              >
                <img src="@/assets/icons/newpinterest.svg" alt="pinterest_img" />
              </a>
            </li>
            <li class="icon" v-if="appConfig.providerDetails.social.youtube">
              <a
                :href="appConfig.providerDetails.social.youtube"
                target="_blank"
                rel="noopener"
                aria-label="pinterest link"
              >
            <img src="@/assets/icons/youtube.svg" alt="youtube" />
              </a>
            </li>
             <li class="icon" v-if="appConfig.providerDetails.social.tiktok" >
              <a
                :href="appConfig.providerDetails.social.tiktok"
                target="_blank"
                rel="noopener"
                aria-label="tiktok"
              >
                <img src="@/assets/icons/tiktok.svg" alt="tiktok_img" />
              </a>
            </li>
            <li class="icon" v-if="appConfig.providerDetails.social.telegram">
              <a
                :href="appConfig.providerDetails.social.telegram"
                target="_blank"
                rel="noopener"
                aria-label="telegram"
              >
                <img src="@/assets/icons/telegram.svg" alt="telegram" />
              </a>
            </li>

          </ul>
        </div>
        <p class="nohover-text">&copy; {{ date.getFullYear() }} {{ $t(`${brand}`) }}</p>
      </div>

      <div class="align_flex">
        <div class="company_container">
          <p class="company_text">{{ $t("Company") }}</p>
          <ul class="company_text_container">
            <li class="text" @click="showAbout">{{ $t("about") }}</li>
            <li>
              <router-link :to="localDisplayLang === 'ara' ? '/ar/privacypolicy' : '/en/privacypolicy'">
                <span class="text">{{ $t("privacy policy") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="localDisplayLang === 'ara' ? '/ar/termsofuse' : '/en/termsofuse'">
                <span class="text">{{ $t("terms of use") }}</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div class="contact_us_container">
          <p class="contact_text">{{ $t("Contact Us") }}</p>
          <ul class="contact_text_container">
            <li>
              <a class="contact_secondary_text" href="mailto:support@noorplay.com">support@noorplay.com</a>
            </li>
            <li class="contact_secondary_text" @click="showFaq">{{ $t("help") }}</li>
          </ul>
        </div>
      </div>

      <div class="download_our_app">
        <p class="download_text">{{ $t("Download Our App") }}</p>
        <ul class="app_links_container">
          <li class="app-links">
            <a :href="appConfig.providerDetails.playstoreURL" target="_blank">
              <img src="@/assets/icons/google-play.png" alt />
            </a>
          </li>
          <li class="app-links">
            <a :href="appConfig.providerDetails.appstoreURL" target="_blank">
              <img src="@/assets/icons/appstore.png" alt />
            </a>
          </li>
          <li class="Huawei_Gallery_Icon">
            <a href="https://appgallery.huawei.com/#/app/C104091027" target="_blank">
              <img src="@/assets/icons/Huawei-app-gallery-1.png" alt="" />
            </a>
          </li>
        </ul>
        <!-- <div class="Huawei_Gallery_Icon">
          
        </div> -->
      </div>
    </div>
    <transition name="popup-animate">
      <div>
        <About v-if="showAboutPopup" :closePopup="() => toggleAboutPopup(false)"></About>
        <Faq v-if="showFaqPopup" :closePopup="() => toggleFaqPopup(false)"></Faq>
        <Contact v-if="showContactPopup" :closePopup="() => hideContactPopup(false)"></Contact>
        <createTicket v-if="enableTicket" :closePopup="() => toggleCreateTicket(false)"></createTicket>
        <AppReferal v-if="showAppShare" :closePopup="() => toggleAppReferal(false)"></AppReferal>
      </div>
    </transition>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapMutations } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";

export default {
  data() {
    return {
      date: new Date(),
      brand: null,
      showAboutPopup: false,
      showFaqPopup: false,
      showContactPopup: false,
      enableTicket: false,
      showAppShare: false,
      net5Logo: require("@/assets/icons/Net5.svg"),
      nammaflixLogo: require("@/assets/icons/nammaflix_logo.png"),
      providerUniqueId: _providerId,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters(["appConfig", "getRtl"]),
  },
  watch: {
    showDeepHelp(val) {
      if (val) {
        console.log("showDeepHelp----->", val);
        this.showFaqPopup();
      }
    },
  },
  created() {
    this.brand =
      _projectName === "VLIVE"
        ? "vLive"
        : _projectName === "NET5"
        ? "Prime Originals"
        : _projectName === "NAMMAFLIX"
        ? "Anviton"
        : _projectName === "Noor Play"
        ? "Noorplay"
        : "";

    this.branchDeeplinks();

    eventBus.$on("show-contact-support", this.showContact);
    eventBus.$on("create-ticket-popup", this.showCreateTicket);
    eventBus.$on("show-share-app-popup", this.showAppReferral);

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    if (localStorage.getItem("setDisplayLanguageCode")) {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    }
  },
  methods: {
    ...mapMutations(["setRtl"]),
    showAbout() {
      this.showAboutPopup = true;
      document.getElementById("body").style.overflowY = "hidden";
    },
    showFaq() {
      this.showFaqPopup = true;
      document.getElementById("body").style.overflowY = "hidden"; // will add later in mixin and reuse wherever necessary
    },
    showContact() {
      this.showFaqPopup = false;
      this.showContactPopup = true;
      document.getElementById("body").style.overflowY = "hidden";
    },
    hideContactPopup(state) {
      this.showContactPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleAboutPopup(state) {
      this.showAboutPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleFaqPopup(state) {
      this.showFaqPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    showCreateTicket(state) {
      this.showFaqPopup = false;
      this.enableTicket = state;
    },
    showAppReferral(state) {
      this.showAppShare = state;
      this.showAboutPopup = false;
    },
    toggleCreateTicket(state) {
      this.enableTicket = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleAppReferal(state) {
      this.showAppShare = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    branchDeeplinks() {
      if (this.showDeepHelp) {
        this.showFaq();
      } else if (this.showDeepAbout) {
        this.showAbout();
      }
    },
  },
  components: {
    About: () => import(/* webpackChunkName: "about" */ "@/components/Popups/AboutUs.vue"),
    Faq: () => import(/* webpackChunkName: "Faq" */ "@/components/Popups/Help/FAQ/Faq.vue"),
    Contact: () =>
      import(/* webpackChunkName: "contact" */ "@/components/Popups/Help/ContactSupport/ContactSupport.vue"),
    createTicket: () =>
      import(/* webpackChunkName: "createTicket" */ "@/components/Popups/ProfilePopups/createTicket.vue"),
    imageItem: () => import(/* Webpack ChunkName: "imageItem" */ "@/components/Templates/imageItem.vue"),
    AppReferal: () => import(/* webpackChunkName: "appReferal" */ "@/components/Popups/AppReferal.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.noorplay_footer {
  background-color: #000000;
  padding: 20px;
  // position: absolute;
  // bottom: 0;
  // width: 100%;
  .footer {
    display: flex;
    // grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    .logo_container {
      .image_item {
        width: 75px;
        height: 28px;
      }
      .icon_list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        li {
          // padding: 0.5rem;
          text-align: center;
          img {
            height: 20px;
            width: 20px;
            opacity: 0.3;
            &:hover {
              opacity: 0.8;
            }
          }
          &:nth-child(even) {
            padding: 0.5rem 0.8rem;
          }
        }
      }
      .nohover-text {
        opacity: 0.6;
        color: #ffffff;
        font-family: $font-regular;
        font-size: 0.9rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1rem;
      }
    }
    .align_flex {
      display: flex;
      justify-content: space-between;
      width: 38%;
      .company_container {
        .company_text {
          color: #ffffff;
          font-family: $font-regular;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 19px;
        }
        .company_text_container {
          margin-top: 10px;
          .text {
            opacity: 0.5;
            color: #ffffff;
            font-family: $font-regular;
            font-size: 0.9rem !important;
            font-weight: 400 !important;
            letter-spacing: 0;
            line-height: 1.5rem;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .contact_us_container {
        .contact_text {
          color: #ffffff;
          font-family: $font-regular;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 19px;
        }
        .contact_text_container {
          margin-top: 10px;
          .contact_secondary_text {
            opacity: 0.5;
            color: #ffffff;
            font-family: $font-regular;
            font-size: 0.9rem !important;
            font-weight: 400 !important;
            letter-spacing: 0;
            line-height: 1.5rem;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    .download_our_app {
      .download_text {
        color: #ffffff;
        font-family: $font-regular;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
        padding: 0 7px;
      }
      .app_links_container {
        display: flex;

        margin-top: 5px;
        .app-links {
          img {
            width: 110px;
            height: 50px;
          }
        }
      }
      .Huawei_Gallery_Icon {
        padding: 8px 8px;
        img {
          width: 105px;
          height: 32px;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .noorplay_footer {
    .footer {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 10px;
      .logo_container {
        .flex_container {
          display: flex;

          justify-content: space-between;
        }
        .icon_list {
          justify-content: normal;
          margin-top: -5px;
        }
      }
      .align_flex {
        margin-top: 10px;
        width: auto;
        .company_container {
          // .company_text {
          // }
          .company_text_container {
            margin: 0;
            .text {
              line-height: 30px;
            }
          }
        }
        .contact_us_container {
          // .contact_text {
          // }
          .contact_text_container {
            margin: 0;
            .contact_secondary_text {
              line-height: 30px;
            }
          }
        }
      }
      .download_our_app {
        text-align: left;
        .download_text {
          padding: 0;
          line-height: 30px;
        }
        .app_links_container {
          padding: 0;
          margin-top: 0;
          display: flex;
          justify-content: space-between;
          .app-links {
            img {
              width: 100px;
              height: 50px;
            }
          }
        }
        .Huawei_Gallery_Icon {
          img {
            width: 100px;
            height: 33px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 640px) {
  .noorplay_footer {
    .footer {
      .download_our_app {
        .app_links_container {
          flex-wrap: wrap;
        }
      }
    }
  }
}
</style>
